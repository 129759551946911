<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <CForm>
                                    <h1>Login</h1>
                                    <p class="text-muted">Sign In to your account</p>
                                    <CInput
                                            placeholder="email"
                                            :value.sync="usr_email"
                                            autocomplete="email"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-user"/>
                                        </template>
                                    </CInput>
                                    <CInput
                                            placeholder="Password"
                                            type="password"
                                            :value.sync="usr_password"
                                            autocomplete="curent-password"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-lock-locked"/>
                                        </template>
                                    </CInput>
                                    <CRow>
                                        <CCol col="6" class="text-left">
                                            <CButton @click="loginUser()" :disabled="buttonDisabled" color="primary"
                                                     class="px-4">Login
                                            </CButton>
                                        </CCol>
                                        <CCol col="6" class="text-right">
                                            <CButton color="link" class="px-0">Forgot password?</CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                        <CCard
                                color="primary"
                                text-color="white"
                                class="text-center py-5 d-md-down-none"
                                body-wrapper
                        >
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
    import {api} from "../../api";

    export default {

        name: 'Login',
        data() {
            return {
                usr_email: "",
                usr_password: "",
                buttonDisabled: false
            }
        },

        methods: {
            loginUser() {
                this.buttonDisabled = true
                api.login(this.usr_email,
                    this.usr_password).then(
                    (res) => {
                        this.buttonDisabled = false;
                        localStorage.setItem('jwt', res.data.jwt)
                        this.$cookies.set('aerdesk', {jwt: res.data.jwt})
                        this.$toast.success("Successfully logged in!")
                        this.$router.push('/dashboard')
                    }
                ).catch((error) => {
                    this.buttonDisabled = false;
                    this.$toast.error("WHOA?")
                })
            }

        }
    }
</script>
